import { DEFAULT_TIMEOUT } from '@/constants/APIConstants'
import { EUDONET_BASE_URL } from '@/constants/Routes'
import axiosLib from 'axios'
import { isDev } from '../isDev'

export const axiosVercelAPI = axiosLib.create({
  timeout: isDev ? 60000 : 30000,
  withCredentials: true
})

export const axiosEUDONETAPI = axiosLib.create({
  baseURL: EUDONET_BASE_URL,
  timeout: DEFAULT_TIMEOUT
})
