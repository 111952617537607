import { UserRole, UserServicePermission } from './FieldsUser'

export const ROLES_PERMISSIONS_MAP = new Map<UserServicePermission, UserRole[]>([
  [
    UserServicePermission.App_Use,
    [
      UserRole.External,
      UserRole.Facilities,
      UserRole.Garage,
      UserRole.Exited,
      UserRole.ExternalFIT,
      UserRole.Employee,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [
    UserServicePermission.BookARoom_Book,
    [
      UserRole.Facilities,
      UserRole.ExternalFIT,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [UserServicePermission.BookARoom_Manage, [UserRole.SuperAdministrator]],
  [UserServicePermission.BookARoom_GenerateStats, [UserRole.SuperAdministrator]],
  [
    UserServicePermission.Camipro,
    [
      UserRole.Facilities,
      UserRole.Garage,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [UserServicePermission.Collaborator_CreateCompany, [UserRole.SuperAdministrator]],
  [
    UserServicePermission.Collaborator_Manage,
    [UserRole.CompanyAdministrator, UserRole.CompanySupervisor, UserRole.SuperAdministrator]
  ],
  [UserServicePermission.Collaborator_ManageCompanyList, [UserRole.SuperAdministrator]],
  [UserServicePermission.Collaborator_EnableCompany, [UserRole.SuperAdministrator]],
  [
    UserServicePermission.CompanyList_Read,
    [
      UserRole.External,
      UserRole.Facilities,
      UserRole.Garage,
      UserRole.Exited,
      UserRole.ExternalFIT,
      UserRole.Employee,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator,
      UserRole.Anonymous
    ]
  ],
  [
    UserServicePermission.CompanyList_Edit,
    [
      UserRole.Garage,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [UserServicePermission.CompanyList_Create, [UserRole.SuperAdministrator]],
  [
    UserServicePermission.Emailing_SelectSubscription,
    [
      UserRole.External,
      UserRole.Facilities,
      UserRole.Garage,
      UserRole.Exited,
      UserRole.ExternalFIT,
      UserRole.Employee,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [
    UserServicePermission.Emailing_EnableChoosingPreferences,
    [UserRole.CompanySupervisor, UserRole.SuperAdministrator]
  ],
  [
    UserServicePermission.Jobs_Read,
    [
      UserRole.External,
      UserRole.Facilities,
      UserRole.Garage,
      UserRole.Exited,
      UserRole.ExternalFIT,
      UserRole.Employee,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator,
      UserRole.Anonymous
    ]
  ],
  [
    UserServicePermission.Jobs_Create,
    [
      UserRole.Garage,
      UserRole.Exited,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [UserServicePermission.Jobs_GenerateStats, [UserRole.SuperAdministrator]],
  [
    UserServicePermission.Fairmove,
    [
      UserRole.Facilities,
      UserRole.Employee,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [
    UserServicePermission.Parkshare_Read,
    [
      UserRole.Facilities,
      UserRole.ExternalFIT,
      UserRole.Employee,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [
    UserServicePermission.Parkshare_Book,
    [
      UserRole.Facilities,
      UserRole.ExternalFIT,
      UserRole.Employee,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [
    UserServicePermission.Parkshare_Manage,
    [UserRole.CompanyAdministrator, UserRole.CompanySupervisor, UserRole.SuperAdministrator]
  ],
  [UserServicePermission.Parkshare_SuperManage, [UserRole.SuperAdministrator]],
  [UserServicePermission.Parkshare_GenerateStats, [UserRole.SuperAdministrator]],
  [
    UserServicePermission.Smart_Guides,
    [
      UserRole.Facilities,
      UserRole.Employee,
      UserRole.CompanyAdministrator,
      UserRole.SuperAdministrator,
      UserRole.CompanySupervisor
    ]
  ],
  [
    UserServicePermission.Smart_Guides_Admin,
    [UserRole.Facilities, UserRole.CompanyAdministrator, UserRole.SuperAdministrator]
  ],
  [UserServicePermission.Smart_Guides_Employee, [UserRole.Employee]],
  [UserServicePermission.Smart_Guides_Supervisor, [UserRole.CompanySupervisor]],
  [
    UserServicePermission.Templates,
    [
      UserRole.Facilities,
      UserRole.Garage,
      UserRole.Employee,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [
    UserServicePermission.Suppliers_Read,
    [
      UserRole.External,
      UserRole.Facilities,
      UserRole.Garage,
      UserRole.Exited,
      UserRole.ExternalFIT,
      UserRole.Employee,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [
    UserServicePermission.Suppliers_Vote,
    [
      UserRole.Facilities,
      UserRole.Garage,
      UserRole.Exited,
      UserRole.ExternalFIT,
      UserRole.Employee,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [UserServicePermission.Suppliers_Manage, [UserRole.SuperAdministrator]],
  [UserServicePermission.Suppliers_GenerateStats, [UserRole.SuperAdministrator]],
  [
    UserServicePermission.Ticketing_Create,
    [
      UserRole.Facilities,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [
    UserServicePermission.Portal_Access,
    [
      UserRole.Garage,
      UserRole.Exited,
      UserRole.External,
      UserRole.ExternalFIT,
      UserRole.Facilities,
      UserRole.Employee,
      UserRole.CompanyAdministrator,
      UserRole.CompanySupervisor,
      UserRole.SuperAdministrator
    ]
  ],
  [UserServicePermission.Space_Needs, [UserRole.CompanyAdministrator, UserRole.CompanySupervisor]]
])
