// noinspection JSUnusedGlobalSymbols

export const EVENT = 100
export const USER = 200
export const COMPANY = 300
export const ADDRESS = 400
export const BAIL_MODULE = 4600
export const PARKING = 7800
export const PARKING_BOOKING = 8000
export const JOBS = 9000
export const ROOMS = 9100
export const EXTRACTOR = 9200
export const NEWS = 9300
export const CAMPUS_EVENT = 9400
export const SUPPLIERS = 9500
export const AGENDA = 9700
export const PARKING_SPOT = 10400

export const OPERATOR_EQUAL = 0
export const OPERATOR_LESS_THAN = 1
export const OPERATOR_LESS_OR_EQUAL_THAN = 2
export const OPERATOR_HIGHER_THAN = 3
export const OPERATOR_HIGHER_OR_EQUAL_THAN = 4
export const OPERATOR_DIFFERENT = 5
export const OPERATOR_BEGIN_WITH = 6
export const OPERATOR_END_WITH = 7
export const OPERATOR_IS_IN_LIST = 8
export const OPERATOR_CONTAINS = 9
export const OPERATOR_IS_EMPTY = 10
export const OPERATOR_TRUE_CHECKBOX = 11
export const OPERATOR_FALSE_CHECKBOX = 12
export const OPERATOR_DOES_NOT_BEGIN_WITH = 13
export const OPERATOR_DOES_NOT_END_WITH = 14
export const OPERATOR_IS_NOT_IN_LIST = 15
export const OPERATOR_DOES_NOT_CONTAIN = 16
export const OPERATOR_IS_NOT_EMPTY = 17
export const OPERATOR_MICROSOFT_SQL_SOUNDEX = 18
export type OPERATOR =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18

export const INTEROPERATOR_AND = 1
export const INTEROPERATOR_OR = 2
export const INTEROPERATOR_EXCEPT = 3
export type INTEROPERATOR = 0 | 1 | 2 | 3

export const authPayload = {
  SubscriberLogin: process.env.EUDONET_SUBSCRIBER_LOGIN,
  SubscriberPassword: process.env.EUDONET_SUBSCRIBER_PASSWORD,
  BaseName: process.env.EUDONET_BASE_NAME,
  UserLogin: process.env.EUDONET_USER_LOGIN,
  UserPassword: process.env.EUDONET_USER_PASSWORD,
  UserLang: process.env.EUDONET_USER_LANG,
  ProductName: process.env.EUDONET_PRODUCT_NAME
}
