import { sentryCategory } from '@/utils/loggingHelpers/sentryHelper'
import { StatusWithPayload } from '@coteries/next'
import { errorHandler } from '@coteries/utils/errors'

export interface MessageObj {
  message?: string | any
  method: string
  category: sentryCategory
}

export class APIError extends Error {
  constructor(public message: string, public errorCode: number, public messageObj?: MessageObj) {
    super(message)
  }
}

export const apiErrorHandler = errorHandler(
  APIError,
  e => ({ status: e.errorCode, payload: { message: e.message } } as StatusWithPayload)
)
