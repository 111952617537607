import { BookARoomContractTypes, UserRole, UserServicePermission } from '@/constants/FieldsUser'
import { ROLES_PERMISSIONS_MAP } from '@/constants/RolePermissionMap'
import { MemberEventSetting } from '@/controller/payloads/EventsPayloads'
import { Company } from '@/models/company/Company'
import { PublicEmployee } from '@/models/company/PublicEmployee'
import { PublicUser } from '@/models/user/PublicUser'
import { User } from '@/models/user/User'
import { UserCompany } from '@/models/user/UserCompany'
import { UserToken } from '@/models/user/UserToken'
import { validateToken } from '@/utils/auth/token'

// Permission helpers

export const hasPermissionRequest = async (
  authToken: string | undefined | null,
  permission: UserServicePermission
) => {
  let user: UserToken | null = null

  if (authToken) {
    try {
      user = await validateToken(authToken)
    } catch (e) {}
  }

  return hasPermissionUser(user, permission)
}

export const hasPermissionUser = (
  user: PublicUser | User | UserToken | null,
  permission: UserServicePermission
): boolean => {
  return hasPermission(permission, (user?.role as UserRole) || UserRole.Anonymous)
}

export const hasPermission = (permission: UserServicePermission, role: UserRole) => {
  return ROLES_PERMISSIONS_MAP.get(permission)?.includes(role) || false
}

/// KNOVA permission functions

export const getCanShareKNOVAFiles = (
  user: User | PublicUser | null,
  company: Company | UserCompany | null
) => {
  if (!user || !company) {
    return false
  }

  return (
    user.bookARoomContractIds?.includes(BookARoomContractTypes.SQIEKNOVA) &&
    company.category.toLowerCase().includes('tenant')
  )
}

export const getCanViewKNOVAEvents = (
  user: User | UserToken | PublicUser | null,
  company: Company | UserCompany | null
) => {
  if (!user || !company) {
    return false
  }

  const role = user.role

  if (isEmpty(role)) {
    return false
  }

  if (role === UserRole.SuperAdministrator) {
    return true
  }

  if (role !== UserRole.CompanySupervisor && role !== UserRole.CompanyAdministrator && role) {
    return false
  }

  return (
    company.agreementType.toLowerCase().includes('sqie') &&
    company.category.toLowerCase().includes('tenant')
  )
}

// Injects knova Member setting parameter into endpoint and enforces user permissions
export const getPermittedKnovaMemberSetting = async (
  userToken?: UserToken,
  memberEventSetting?: string
): Promise<MemberEventSetting> => {
  // Can't show member events if the user does not have permission
  if (memberEventSetting !== 'none' && !userToken?.isKnovaMember) {
    return 'none'
  }

  return (memberEventSetting ?? 'none') as MemberEventSetting
}

/// Advanced permission functions

export const getCanCompanyRequestSpace = (company: UserCompany | null) => {
  if (!company) {
    return false
  }

  return ['feip-lausanne', 'feip-cbip', 'feip-unlimitrust'].includes(
    company.agreementType.toLocaleLowerCase()
  )
}

export const getCanEditEmployee = (
  user: UserToken | PublicUser,
  employee: Omit<PublicEmployee, 'encryptedId'>
) => {
  const role = user.role

  if (isEmpty(role)) {
    return false
  }

  //A user can edit himself
  if (user.hashedId === employee.hashedId) {
    return true
  }

  //Only these roles can ever edit
  if (
    role !== UserRole.CompanySupervisor &&
    role !== UserRole.CompanyAdministrator &&
    role !== UserRole.SuperAdministrator &&
    role !== UserRole.Employee &&
    role !== UserRole.Facilities
  ) {
    return false
  }

  //Employees and Facilities can edit themselves
  if (
    (role === UserRole.Employee || role === UserRole.Facilities) &&
    user.hashedId !== employee.hashedId
  ) {
    return false
  }

  //The rest of the top-down edit hierarchy
  if (
    role === UserRole.CompanyAdministrator &&
    (employee.role === UserRole.CompanyAdministrator ||
      employee.role === UserRole.CompanySupervisor ||
      employee.role === UserRole.SuperAdministrator)
  ) {
    return false
  }

  return !(role === UserRole.CompanySupervisor && employee.role === UserRole.SuperAdministrator)
}

export const getCanDeleteEmployee = (
  user: UserToken | PublicUser,
  employee: Omit<PublicEmployee, 'encryptedId'>
) => {
  if (user.role === UserRole.Employee || user.role === UserRole.Facilities) {
    return false
  }

  //A user cannot delete himself
  if (user.hashedId === employee.hashedId) {
    return false
  }

  return getCanEditEmployee(user, employee)
}

export const getCanEditCompany = (role: string) => {
  if (isEmpty(role)) {
    return false
  }

  return [
    UserRole.Garage.toString(),
    UserRole.CompanyAdministrator.toString(),
    UserRole.CompanySupervisor.toString(),
    UserRole.SuperAdministrator.toString()
  ].includes(role)
}

export const getRoleSubordinates = (role: UserRole) => {
  switch (role) {
    case UserRole.CompanyAdministrator:
      return [UserRole.Employee, UserRole.Facilities, UserRole.CompanyAdministrator]
    case UserRole.CompanySupervisor:
      return [
        UserRole.Employee,
        UserRole.Facilities,
        UserRole.CompanyAdministrator,
        UserRole.CompanySupervisor
      ]
    case UserRole.SuperAdministrator:
      return [
        UserRole.Exited,
        UserRole.External,
        UserRole.ExternalFIT,
        UserRole.Employee,
        UserRole.Garage,
        UserRole.Facilities,
        UserRole.CompanyAdministrator,
        UserRole.CompanySupervisor,
        UserRole.SuperAdministrator
      ]
    default:
      return []
  }
}

const isEmpty = (str: string) => !str || str.length === 0
