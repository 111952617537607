// noinspection JSUnusedGlobalSymbols

export const CONTACT_LAST_NAME = 201
export const CONTACT_FIRST_NAME = 202
export const CONTACT_CIVILITY = 204
export const CONTACT_SINCE = 205
export const CONTACT_EMAIL = 208
export const CONTACT_PP_ID = 211
export const CONTACT_FUNCTION = 213
export const CONTACT_ARCHIVED = 214
export const CONTACT_RESPONSIBLE = 215
export const CONTACT_ROLE = 250
export const CONTACT_PHONE = 217
export const CONTACT_RECIPIENT_OF = 218
export const CONTACT_REFERENT = 219
export const CONTACT_APPEAL_ORDER = 220
export const CONTACT_STOP_MAIL = 221
export const CONTACT_SOURCE = 222
export const CONTACT_LANDLORD = 223
export const CONTACT_MOBILE_PHONE = 224
export const CONTACT_FILE_ORIGIN = 225
export const CONTACT_AVATAR = 275
export const CONTACT_DESCRIPTION = 289
export const CONTACT_INFORMATION = 293
export const CONTACT_NOTE = 294
export const CONTACT_CREATED_ON = 295
export const CONTACT_EDITED_ON = 296
export const CONTACT_CREATED_BY = 297
export const CONTACT_EDITED_BY = 298
export const CONTACT_BELONGS_TO = 299
export const CONTACT_SKILLS = 206
export const CONTACT_USERNAME = 226
export const CONTACT_CREDIT_PARKSHARE = 227
export const CONTACT_CAN_REDEPLOY = 206
export const CONTACT_CAN_CHANGE_COMPANY = 209
export const CONTACT_CAN_MANAGE_EVENT_NEWS = 210

export enum USER_ROLE_VALUES {
  Anonymous = 4617,
  CompanyAdministrator = 4620,
  CompanySupervisor = 4621,
  Employee = 4619,
  Exited = 4851,
  External = 4618,
  ExternalFIT = 4808,
  Facilities = 5456,
  Garage = 5464,
  SuperAdministrator = 4622
}

export enum UserServicePermission {
  App_Use,
  BookARoom_Book,
  BookARoom_Manage,
  BookARoom_GenerateStats,
  Camipro,
  Collaborator_CreateCompany,
  Collaborator_Manage,
  Collaborator_ManageCompanyList,
  Collaborator_EnableCompany,
  CompanyList_Read,
  CompanyList_Edit,
  CompanyList_Create,
  Emailing_SelectSubscription,
  Emailing_EnableChoosingPreferences,
  Jobs_Read,
  Jobs_Create,
  Jobs_GenerateStats,
  Parkshare_Read,
  Parkshare_Book,
  Parkshare_Manage,
  Parkshare_SuperManage,
  Parkshare_GenerateStats,
  Fairmove,
  Smart_Guides,
  Smart_Guides_Admin,
  Smart_Guides_Employee,
  Smart_Guides_Supervisor,
  Templates,
  Suppliers_Read,
  Suppliers_Vote,
  Suppliers_Manage,
  Suppliers_GenerateStats,
  Ticketing_Create,
  Portal_Access,
  Space_Needs
}

export enum UserRole {
  Anonymous = 'Anonymous',
  CompanyAdministrator = 'Company Administrator',
  CompanySupervisor = 'Company Supervisor',
  Employee = 'Employee',
  Exited = 'Exited',
  External = 'External',
  ExternalFIT = 'External FIT',
  Facilities = 'Facilities',
  Garage = 'Garage',
  SuperAdministrator = 'Super Administrator'
}

export enum BookARoomContractTypes {
  SQIE = 4852,
  FEIP_CBIP = 4853,
  FEIPLausanne = 4854,
  GARAGE = 4855,
  SQIEKNOVA = 5452,
  FEIP_PRILLY = 5596
}

export const getEudonetValueFromUserRole = (role: UserRole): USER_ROLE_VALUES => {
  switch (role) {
    case UserRole.Anonymous:
      return USER_ROLE_VALUES.Anonymous
    case UserRole.CompanyAdministrator:
      return USER_ROLE_VALUES.CompanyAdministrator
    case UserRole.CompanySupervisor:
      return USER_ROLE_VALUES.CompanySupervisor
    case UserRole.Employee:
      return USER_ROLE_VALUES.Employee
    case UserRole.Exited:
      return USER_ROLE_VALUES.Exited
    case UserRole.External:
      return USER_ROLE_VALUES.External
    case UserRole.ExternalFIT:
      return USER_ROLE_VALUES.ExternalFIT
    case UserRole.Facilities:
      return USER_ROLE_VALUES.Facilities
    case UserRole.Garage:
      return USER_ROLE_VALUES.Garage
    case UserRole.SuperAdministrator:
      return USER_ROLE_VALUES.SuperAdministrator
  }
}
