// noinspection JSUnusedGlobalSymbols

export const MAX_TIMEOUT = 60000
export const TIMEOUT_INTERVAL = 10000
export const DEFAULT_TRIES = 7
export const DEFAULT_TIMEOUT = 30000
export const MAX_FILE_SIZE_2_MB = 1048576 * 2
export const PAGE_SIZE_ITEMS_COUNT = 5000

export const NEWS_KEY_FIGURE_MAX_LENGTH = 10

export enum ROLE_SECURED_PERMISSION {
  CAN_EDIT_NEWS_EVENTS = 'CAN_EDIT_NEWS_EVENTS',
  CAN_EDIT_COMPANIES_LOGO = 'CAN_EDIT_COMPANIES_LOGO',
  CAN_REDEPLOY_WEBSITE = 'CAN_REDEPLOY_WEBSITE'
}

export enum ALLOWED_CORS_SOURCE {
  PORTAL = 'portal',
  APP = 'app',
  CURL = 'curl'
}
