import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import { isDev } from '../isDev'
import Cookies from 'js-cookie'

declare global {
  interface Window {
    plausible: any
  }
}

const hasAcceptedGA = () => {
  const cookie = Cookies.get('axeptio_authorized_vendors')
  return cookie?.includes('google_analytics') ?? false
}

export const initTracking = () => {
  if (!isDev) {
    process.env.NEXT_PUBLIC_GA_PROPERTY && ReactGA.initialize(process.env.NEXT_PUBLIC_GA_PROPERTY)
    process.env.NEXT_PUBLIC_GTM_PROPERTY &&
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_PROPERTY
      })
  }
}

export const pageView = () => {
  if (!isDev && hasAcceptedGA()) {
    ReactGA.send('pageview')
  }
}
