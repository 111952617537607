///_middleware does not support the bcrypt plugin.
///All implementation of bcrypt need to be aggressively isolated so that the _middleware doesn't accidentally import it.

import bcrypt from 'bcryptjs'

const backup = '$2b$10$jI8VztkXubJ7jvPt.e.XseTmxxPs/wo8BCmUuVSU4duYw3/nADDQ6'

export const validatePassword = async (
  password: string,
  hashedPassword: string
): Promise<boolean> => {
  if (password.length < 0 || hashedPassword.length < 0) {
    return false
  }

  return bcrypt.compare(password, hashedPassword)
}

//Generate a default salted hash which does not guarantee the same hash string with the same input
export const generateSaltedHash = async (data: string) => {
  const salt = await bcrypt.genSalt()
  return bcrypt.hash(data, salt)
}

//Generate a fixed-salt hash which guarantees the same hash string with the same input
export const fixedHash = async (data: string) => {
  try {
    return await bcrypt.hash(data, backup)
  } catch (e) {
    console.error(e)
    throw e
  }
}
