///OBS: We need to separate the password functions from this script since the _middleware cannot compile the bcrypt library.
///OBS: Never use this client-side. If the need arises, validation should be separated, so the private key is not issued in the client bundle.

import { COOKIE_AUTH_TOKEN } from '@/constants/RequestConstants'
import { APIError } from '@/models/APIError'
import { User } from '@/models/user/User'
import { UserToken } from '@/models/user/UserToken'
import { isDevOrPreview } from '@/utils/isDev'
import { CookieSerializeOptions, serialize } from 'cookie'
import { SignJWT, decodeJwt, importPKCS8, importSPKI, jwtVerify } from 'jose'
import { NextApiRequest, NextApiResponse } from 'next'
import { fixedHash } from './bcryption'

const expiresIn = '90d'
const expiresInResetPassword = '10m'
const issuer = 'coteries-api'
const audience = 'https://www.epfl-innovationpark.ch/'

const tokenPublicKey = async () => {
  const publicKeyBase46 = process.env.COOKIE_PUBLIC_KEY
  const publicKeyString = atob(publicKeyBase46!)

  return importSPKI(publicKeyString, 'ES256')
}

const tokenPrivateKey = async () => {
  const privateKeyBase64 = process.env.COOKIE_PRIVATE_KEY
  const privateKeyString = atob(privateKeyBase64!)

  return importPKCS8(privateKeyString, 'ES256')
}

const makeToken = async (payload: any, expiration: string): Promise<string> => {
  const key = await tokenPrivateKey()

  return new SignJWT({ ...payload })
    .setProtectedHeader({ alg: 'ES256' })
    .setIssuedAt()
    .setIssuer(issuer)
    .setAudience(audience)
    .setExpirationTime(expiration)
    .sign(key)
}

export const createOrRefreshToken = async (
  user: UserToken | User,
  isKnovaMember?: boolean
): Promise<string> => {
  const payload = {
    userId: user.userId,
    hashedId: (user as UserToken).hashedId ?? (await fixedHash(user.userId.toString())),
    isKnovaMember: (user as UserToken).isKnovaMember ?? !!isKnovaMember,
    companyId: user.companyId,
    role: user.role,
    canRedeploy: user.canRedeploy,
    canChangeCompany: user.canChangeCompany,
    canManageEventNews: user.canManageEventNews
  } as UserToken

  return makeToken(payload, expiresIn)
}

export const createPasswordResetToken = async (userId: number): Promise<string> => {
  return makeToken({ userId }, expiresInResetPassword)
}

export const validateToken = async (token: string): Promise<UserToken> => {
  const key = await tokenPublicKey()
  const value = (
    await jwtVerify(token, key, {
      issuer,
      audience
    })
  ).payload as unknown as UserToken
  return value
}

export const decodeToken = (token: string): UserToken => {
  const decoded = decodeJwt(token)
  return decoded as UserToken
}

export const getCookieOptions = (): CookieSerializeOptions => {
  // noinspection SpellCheckingInspection
  const domain = isDevOrPreview ? '' : '.epfl-innovationpark.ch'
  return {
    path: '/',
    httpOnly: false,
    domain,
    secure: process.env.NODE_ENV === 'production',
    maxAge: 1000 * 60 * 60 * 24 * 30 * 4
  }
}

export async function isLoggedIn(req: NextApiRequest, res: NextApiResponse): Promise<UserToken> {
  const user = await getUserToken(req, res)

  if (!user) {
    throw new APIError('User is not logged or missing auth token', 401)
  }

  return user
}

export async function getUserToken(
  req: NextApiRequest,
  res: NextApiResponse
): Promise<UserToken | null> {
  const token = req.cookies[COOKIE_AUTH_TOKEN]

  if (!token) {
    return null
  }
  try {
    const user = await getTokenUserFromToken(token)

    const newToken = await createOrRefreshToken(user)
    res.setHeader('Set-Cookie', serialize(COOKIE_AUTH_TOKEN, newToken, getCookieOptions()))

    return user
  } catch (e) {
    console.warn(e)
    return null
  }
}

export async function getTokenUserFromToken(token: string): Promise<UserToken> {
  const decodedToken = await validateToken(token)
  if (!decodedToken?.userId) {
    throw new APIError('Error when validating the token', 402, {
      method: 'getTokenUserFromToken',
      category: 'AUTH',
      message: decodedToken?.userId
    })
  }

  return decodedToken
}
