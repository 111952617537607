import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
    @font-face {
      font-family: 'Moderat';
      src: url('/fonts/Moderat-Regular.woff2') format('woff2');
    } `}
  />
)

export default Fonts
