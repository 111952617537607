import Fonts from '@/components/Font'
import userStore, { limitedUpdateUserInfo, storeIntegrityCheck } from '@/stores/userStore'
import { initTracking, pageView } from '@/utils/loggingHelpers/eventTracking'
import { config } from '@fortawesome/fontawesome-svg-core'
import PlausibleProvider from 'next-plausible'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import '@/styles/portal/parkshare.css'
import '@/styles/styles.css'
import '@fortawesome/fontawesome-svg-core/styles.css'

export { reportWebVitals } from 'next-axiom'

config.autoAddCss = false

function MyApp({ Component, pageProps }: AppProps) {
  const userData = userStore()
  const router = useRouter()

  //This is needed on all pages to add an event on the doc to run javascript at the correct time
  const event = new Event('pageMount')
  useEffect(() => {
    document.dispatchEvent(event)
    // eslint-disable-next-line
  }, [])

  //On any user store change at all, if any data is missing or the user is logged out, remove any exising data.
  useEffect(() => {
    storeIntegrityCheck()
  }, [userData])

  //Check if the user data should be updated. Necessary since we can't have swr or unlimited checks on eudonet.
  setInterval(() => {
    // noinspection JSIgnoredPromiseFromCall
    limitedUpdateUserInfo()
  }, 3600000) //1 hour

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    limitedUpdateUserInfo()
  }, [])

  useEffect(() => {
    initTracking()
  }, [])

  useEffect(() => {
    pageView()
  }, [router.pathname])

  //Set the token when accessing the platform from the mobile application
  useEffect(() => {
    const { token } = router.query
    if (
      token &&
      !router.asPath.includes('/login') &&
      !Array.isArray(token) &&
      !userData.isLoggedIn
    ) {
      // noinspection JSIgnoredPromiseFromCall
      userData.tokenLogin(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  // noinspection SpellCheckingInspection
  return (
    <PlausibleProvider domain='epfl-innovationpark.ch'>
      <Fonts />
      <Component {...pageProps} suppressHydratationWarning />
    </PlausibleProvider>
  )
}

// noinspection JSUnusedGlobalSymbols
export default MyApp
