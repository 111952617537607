import { ALLOWED_CORS_SOURCE } from '@/constants/APIConstants'
import { COOKIE_AUTH_TOKEN } from '@/constants/RequestConstants'
import {
  forceAPICacheRefresh,
  FRONT_COMPANY,
  FRONT_CURRENT_USER,
  FRONT_FORGOT_PASSWORD,
  FRONT_LOGIN,
  FRONT_RESET_PASSWORD,
  FRONT_TOKEN_LOGIN
} from '@/constants/Routes'
import { EditUser } from '@/models/user/EditUser'
import { PublicUser } from '@/models/user/PublicUser'
import { UserCompany } from '@/models/user/UserCompany'
import { getCanViewKNOVAEvents } from '@/utils/apiHelpers/userPermissions'
import { axiosVercelAPI } from '@/utils/connectionHelpers/axios'
import { isDevOrPreview } from '@/utils/isDev'
import { AxiosError } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import Cookies from 'js-cookie'
import { create, StoreApi } from 'zustand'
import { persist } from 'zustand/middleware'

interface ZustandStore {
  //Store data
  user: PublicUser | null
  company: UserCompany | null
  isKnovaMember: boolean

  //Login functions
  isLoggedIn: boolean
  isLoggingIn: boolean
  login: (email: string, password: string) => Promise<void | AxiosError>
  tokenLogin: (token: string) => Promise<void | AxiosError>
  logout: () => void

  //Store update timer so we don't pull too often
  lastUserCheck: Dayjs | null
  updateCompany: () => Promise<void>
}

// noinspection JSUnusedLocalSymbols
const userStore = create<ZustandStore>()(
  persist(
    set =>
      ({
        user: null,
        company: null,
        isKnovaMember: false,
        isLoggedIn: false,
        isLoggingIn: false,
        login: async (email, password) => loginImpl(email, password, set),
        tokenLogin: async token => tokenLoginImpl(token, set),
        logout: () => logoutImpl(set),
        lastUserCheck: null,
        updateCompany: async () => updateCompany(set)
      } as ZustandStore),
    { name: 'auth' }
  )
)

const loginImpl = async (
  email: string,
  password: string,
  set: StoreApi<ZustandStore>['setState']
) => {
  set({ isLoggingIn: true })

  try {
    const response = await axiosVercelAPI.post(FRONT_LOGIN, {
      email,
      password,
      source: ALLOWED_CORS_SOURCE.PORTAL
    })

    set({
      lastUserCheck: dayjs(),
      user: response.data.user,
      company: response.data.company,
      isKnovaMember: getCanViewKNOVAEvents(response.data.user, response.data.company),
      isLoggedIn: true,
      isLoggingIn: false
    })
  } catch (e: any) {
    set({ isLoggingIn: false })
    return e as AxiosError
  }
}

const tokenLoginImpl = async (token: string, set: StoreApi<ZustandStore>['setState']) => {
  set({ isLoggingIn: true })

  Cookies.set(COOKIE_AUTH_TOKEN, token, {
    path: '/',
    domain: isDevOrPreview ? '' : '.epfl-innovationpark.ch',
    expires: 1000 * 60 * 60
  })

  try {
    const response = await axiosVercelAPI.post(FRONT_TOKEN_LOGIN, {
      token,
      source: ALLOWED_CORS_SOURCE.PORTAL
    })

    set({
      lastUserCheck: dayjs(),
      user: response.data.user,
      company: response.data.company,
      isKnovaMember: getCanViewKNOVAEvents(response.data.user, response.data.company),
      isLoggedIn: true,
      isLoggingIn: false
    })
  } catch (e: any) {
    set({ isLoggingIn: false })
    return e as AxiosError
  }
}

const logoutImpl = (set: StoreApi<ZustandStore>['setState']) => {
  Cookies.remove(COOKIE_AUTH_TOKEN, {
    path: '/',
    domain: isDevOrPreview ? '' : '.epfl-innovationpark.ch'
  })

  set({ user: null, company: null, isKnovaMember: false, isLoggedIn: false, lastUserCheck: null })
}

export const storeIntegrityCheck = () => {
  const userData = userStore.getState()

  if (Cookies.get(COOKIE_AUTH_TOKEN)) {
    if (!userData.isLoggingIn && (!userData.user || !userData.company || !userData.isLoggedIn)) {
      userData.logout()
    }
  } else {
    if (!userData.isLoggingIn && (userData.user || userData.company || userData.isLoggedIn)) {
      userData.logout()
    }
  }
}

export const limitedUpdateUserInfo = async () => {
  const store = userStore.getState()

  //User is not logged in, no request to be done
  if (!store.isLoggedIn && !store.lastUserCheck) {
    return
  }

  //User is logged in but last check was less than 24 hours ago, no request to be done
  if (store.isLoggedIn && dayjs().diff(store.lastUserCheck, 'hour') < 23) {
    return
  }

  try {
    const user = await axiosVercelAPI.get(FRONT_CURRENT_USER)
    const company = await axiosVercelAPI.get(FRONT_COMPANY)

    userStore.setState({
      user: user.data,
      company: company.data,
      isKnovaMember: getCanViewKNOVAEvents(user.data, company.data),
      lastUserCheck: dayjs()
    })
  } catch (e) {
    const error = e as AxiosError
    const status = error.response?.status
    if (status && status > 399 && status < 500) {
      logoutImpl(userStore.setState)
    }
  }
}

export const updateUserInfo = async (userData: EditUser) => {
  const user = userStore.getState().user

  if (!user) {
    return
  }

  user.parkshareCredits = userData.parkshareCredits || user.parkshareCredits
  user.username = userData.username || user.username
  user.firstName = userData.firstName || user.firstName
  user.lastName = userData.lastName || user.lastName
  user.role = userData.role || user.role
  user.email = userData.email || user.email
  user.picture = userData.picture || user.picture

  userStore.setState({
    user,
    isKnovaMember: getCanViewKNOVAEvents(user, userStore.getState().company)
  })
}

const updateCompany = async (set: StoreApi<ZustandStore>['setState']) => {
  const company = await axiosVercelAPI.get(forceAPICacheRefresh(FRONT_COMPANY))
  set({
    company: company.data,
    isKnovaMember: getCanViewKNOVAEvents(userStore.getState().user, company.data)
  })
}

export const forgotPassword = async (email: string) => {
  try {
    await axiosVercelAPI.post(FRONT_FORGOT_PASSWORD, { email, source: ALLOWED_CORS_SOURCE.PORTAL })
  } catch (e) {
    return e as AxiosError
  }
}

export const resetPassword = async (password: string, token: string) => {
  try {
    await axiosVercelAPI.post(FRONT_RESET_PASSWORD, {
      password,
      token,
      source: ALLOWED_CORS_SOURCE.PORTAL
    })
  } catch (e: any) {
    return e as AxiosError
  }
}

// noinspection JSUnusedGlobalSymbols
export default userStore
